import useResponsive from 'src/sdk/responsive/useResponsive'
import './search-quadrant.scss'

import QuadrantImage from '../../../images/quadrantImage.webp'

function SearchQuadrant() {
  const { isPortrait } = useResponsive()
  const isMobile = isPortrait ? `mobile-quadrant` : `desktop-quadrant`

  return (
    <section className={` bg-partner-color-dark w-100`}>
      <div className={`${isMobile} flex layout__content`}>
        <article className="w-50 flex flex-col j-between my-3 pr-7">
          <h5 className="partner-color-light-gray py-1 section-title">
            Quadrante VTEX 2023
          </h5>
          <div>
            <p className="partner-color-neutral-t py-1">
              Criado para reconhecer anualmente as agências parceiras da VTEX, o
              Quadrante concretiza os resultados de sucesso da operação de
              resellers e implementadores.
            </p>
            <p className="py-1 partner-color-neutral-t py-1">
              Os dois eixos principais mensuram a eficiência em vendas dos
              projetos de comércio digital na plataforma VTEX (Effectiveness to
              Sell) e a habilidade técnica de executá-los (Ability to Execute).{' '}
            </p>
          </div>
          <a
            href="/"
            className="p-1 my-1 display-block bg-partner-color-pink no-decoration partner-color-light-gray b-radius-5 t-center mw-250-px"
          >
            Ver Parceiros do Quadrante
          </a>
        </article>
        <img
          className="w-50"
          src={QuadrantImage}
          alt="Quadrante de agências 2022"
        />
      </div>
    </section>
  )
}

export default SearchQuadrant
