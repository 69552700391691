import { graphql, useStaticQuery } from 'gatsby'
import { useEffect, useState } from 'react'

interface SearchTopProps {
  facet: string
}

interface SearchType {
  searchText: string
  searchTitle: string
  searchURL: string
}

function SearchTop(props: SearchTopProps) {
  const data = useStaticQuery(graphql`
    query searchQuery {
      cmsSearchPages {
        searchVariables {
          searchTexts {
            searchItems {
              searchText
              searchTitle
              searchURL
            }
          }
        }
      }
    }
  `)

  const initialValue = {
    title: 'Resultados',
    text: 'Encontre as melhores soluções abaixo',
  }

  const [search, setSearch] = useState(initialValue)

  const searchArray =
    data?.cmsSearchPages?.searchVariables?.searchTexts?.searchItems ?? []

  const { facet } = props

  useEffect(() => {
    searchArray.every((item: SearchType) => {
      if (item.searchURL === facet) {
        setSearch({
          title: item.searchTitle,
          text: item.searchText,
        })

        return false
      }

      setSearch(initialValue)

      return true
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facet, searchArray])

  return (
    <section className="bg-partner-color-dark partner-color-neutral-s w-100 flex j-center a-center flex-col py-3">
      <h3 className="text-center partner-color-light-gray py-1 section-title t-center">
        {search.title}
      </h3>
      <p className="partner-color-neutral-s mw-1110px t-center mx-1 py-1">
        {search.text}
      </p>
    </section>
  )
}

export default SearchTop
